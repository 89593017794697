import React, { ReactNode, useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogContent,
  Heading,
} from '@charlietango/ui';
import useId from '@charlietango/use-id';
import { rem } from 'polished';

import { useDictionary } from '../../hooks/useDictionary';
import CloseIcon from '../../icons/CloseIcon';
import { ButtonVariant } from '../../styles/buttons';
import { Colors } from '../../styles/colors';
import { TextVariant } from '../../styles/typography';
import { motion } from '../../utils/motion';
import Button from '../Button/Button';
import Logo from '../Logo/Logo';

export type ModalAction = {
  label: string;
  href?: string;
  onClick?: () => void;
  variation: ButtonVariant;
  submitting?: boolean;
};

export type ModalProps = {
  title?: string;
  children?: ReactNode | ReactNode[];
  actions?: ModalAction[];
  isOpen?: boolean;
  onDismiss?: () => void;
  noLogo?: boolean;
};

const noop = () => {};

const modalVariants = {
  show: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.3,
    },
  },
};

function Modal({
  isOpen,
  onDismiss,
  noLogo,
  children,
  title,
  actions,
}: ModalProps) {
  const { t } = useDictionary();
  const modalHeaderId = useId('modal-header');

  // Normally we would use `<AnimatePresence>` from ../../utils/motion for handling if we should render.
  // But, for some reason this creates an obscure error on some iPhones, that breaks the animation early
  // leaving us in a faded state.
  const [shouldRender, setShouldRender] = useState(isOpen);

  // If the modal isn't open, and it shouldn't render, just return `null`
  if (!isOpen && !shouldRender) return null;

  return (
    <Dialog
      isOpen
      onDismiss={onDismiss || noop}
      initialFocus={'[data-primary="true"]'}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      <motion.div
        animate={isOpen ? 'show' : 'hide'}
        initial="hide"
        onAnimationComplete={() => {
          setShouldRender(isOpen);
        }}
        onAnimationStart={() => {
          setShouldRender(true);
        }}
        variants={modalVariants}
        sx={{
          px: [4, rem(72), 0],
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100%',
        }}
      >
        <DialogBackdrop
          sx={{ backgroundColor: Colors.BorderGrey, opacity: 0.7 }}
        />
        <DialogContent
          aria-labelledby={modalHeaderId}
          sx={{
            backgroundColor: Colors.PureWhite,
            boxShadow: '0 5px 18px 0 rgba(0, 0, 0, 0.05)',
            maxWidth: 625,
            minWidth: [null, 500],
            pt: 9,
            pb: 6,
            px: [5, 9],
          }}
        >
          {onDismiss ? (
            <Button
              variant={ButtonVariant.Flat}
              onClick={onDismiss}
              aria-label={t('modal.closeDialog')}
              sx={{
                width: 'auto',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon />
            </Button>
          ) : null}
          <div>
            {!noLogo ? <Logo animateIn sx={{ mb: 4 }} /> : null}
            {title && (
              <Heading
                sx={{ mb: 4 }}
                id={modalHeaderId}
                variant={TextVariant.H3}
                // Modals er essentially new pages, so dialog title should be a h1
                as="h1"
              >
                {title}
              </Heading>
            )}
            {children}
            <div
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: actions ? [9] : rem(80),
                borderTop: actions ? ['none', '1px solid'] : 'none',
                borderTopColor: ['none', Colors.BorderGrey],
                pt: actions ? 6 : 0,
              }}
            >
              {actions && (
                <div
                  sx={{
                    width: ['100%', 'auto'],
                    display: 'flex',
                    flexDirection: ['column', 'row-reverse'],
                    justifyContent: 'flex-end',
                  }}
                >
                  {actions.map(
                    (
                      { label, onClick, href, variation, submitting },
                      index,
                    ) => (
                      <Button
                        data-primary={index === 0}
                        key={label + index}
                        onClick={onClick}
                        href={href}
                        variant={variation}
                        submitting={submitting}
                        sx={{
                          width: ['100%', 'auto'],
                          minWidth: 130,
                          mt: index !== 0 ? [3, 0] : undefined,
                          mr: index !== 0 ? [undefined, 2] : undefined,
                        }}
                      >
                        {label}
                      </Button>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </motion.div>
    </Dialog>
  );
}

export default Modal;
